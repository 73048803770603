<template>
  <div class="report-view skill-report pb-5">
    <div class="viewEdit ttrInfo">

      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <!-- Resource Allocation by Skills Report, Calander -->
        <div class="calendar justify-content-between mt-0 custom-width">
          <p class="task-timesheet">
            Resource Allocation by Skills Report
          </p>
          <!-- Previous Arrow, Calander, Next Arrow -->
          <div class="calendar mt-0 justify-content-center align-items-center calendarBg">
            <div
              id="date-select"
              class="date-select mr-0"
            >
              <div class="date-dropdown">
                <VueDatePicker
                  v-model="startDate"
                  :type="'month'"
                  no-header
                />
              </div>
            </div>
            <Help
              v-show="$store.state.app.userManualList"
              code="SR"
            />
          </div>
        </div>
      </b-card>
      <!-- end date picker toggle -->

      <!-- start Filters & export -->
      <div class="card-filter-section">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="d-flex align-items-center justify-content-start flex-wrap">
            <p class="filter-title">
              Filters:
            </p>
            <div>
              <custom-dropdown
                v-model="manage_by"
                :options="manage_by_users"
                label="Managed by"
                icon="EditIcon"
              />
            </div>

            <div>
              <custom-dropdown
                v-model="reporting_to"
                :options="reporting_to_users"
                label="Reporting to"
                icon="EditIcon"
              />
            </div>
            <div>
              <custom-dropdown
                v-if="userInfo && userInfo.role == 'SA'"
                v-model="group_id"
                :options="groups"
                label="Group"
                icon="EditIcon"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="">
              <button
                class="apply-filter filterRedesign mr-1"
                @click="ApplyFilter()"
              >
                Apply Filter
              </button>
              <button
                class="apply-filter-clear filterRedesign"
                @click="clearFilter()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end Filters & export -->

      <div class="report-task-section">
        <table-loader
          v-if="loader"
          :fields="fields"
        />
        <div v-else-if="items.length">
          <div class="tableDesigns align-th-table">
            <b-table
              :fields="fields"
              :items="items"
              sticky-header
            >
              <template #head(full_time)>
                Full Time <span>(120+ Hours)</span>
              </template>
              <template #head(part_time)>
                Part Time <span>(40-119 Hours)</span>
              </template>
              <template #head(available)>
                Available <span>(0-39 Hours)</span>
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <p
                    v-b-tooltip.hover.bottom="data.item.name"
                    @click="showSkillMemberModel(data.item, 'total')"
                  >
                    {{ data.item.name }}
                  </p>
                </div>
              </template>
              <template #cell(full_time)="data">
                <div class="d-flex align-items-center">
                  <p
                    v-b-tooltip.hover.bottom="getTooltipText(data.item.skills.fullTime)"
                    @click="showSkillMemberModel(data.item, 'full_time')"
                  >
                    {{ getTooltipText(data.item.skills.fullTime) }}
                  </p>
                </div>
              </template>
              <template #cell(part_time)="data">
                <div class="d-flex align-items-center">
                  <p
                    v-b-tooltip.hover.bottom="getTooltipText(data.item.skills.part_time)"
                    @click="showSkillMemberModel(data.item, 'part_time')"
                  >
                    {{ getTooltipText(data.item.skills.part_time) }}
                  </p>
                </div>
              </template>
              <template #cell(available)="data">
                <div class="d-flex align-items-center">
                  <p
                    v-b-tooltip.hover.bottom="getTooltipText(data.item.skills.available)"
                    @click="showSkillMemberModel(data.item, 'available')"
                  >
                    {{ getTooltipText(data.item.skills.available) }}
                  </p>
                </div>
              </template>
              <template #cell(total)="data">
                <div class="d-flex align-items-center">
                  <p
                    v-b-tooltip.hover.bottom="getTooltipText(data.item.skills.total)"
                    @click="showSkillMemberModel(data.item, 'total')"
                  >
                    {{ getTooltipText(data.item.skills.total) }}
                  </p>
                </div>
              </template>
              <template
                slot="bottom-row"
                slot-scope
              >
                <th>Total Count</th>
                <th>{{ full_time_total }} Members</th>
                <th>{{ part_time_total }} Members</th>
                <th>{{ available_total }} Members</th>
                <th>{{ all_total }} Members</th>
              </template>
            </b-table>
          </div>

          <!-- Modal Data -->
          <b-modal
            id="resource-modal"
            v-model="is_model"
            class="resource-modal"
            size="lg"
            centered
            @hidden="closeModal"
          >
            <div class="resource-modal-content reportmodalText">
              <div class="resource-profile viewEdit d-flex justify-content-between align-items-center mt-1 mb-2 px-1">
                <div class="d-flex">
                  <h4 class="m-0">
                    Skills :
                  </h4><span>{{ skill_name }}</span>
                </div>
                <div class="calendar justify-content-center calendarBg m-0">
                  <div
                    id="date-select"
                    class="date-select"
                  >
                    <VueDatePicker
                      v-model="modalDate"
                      type="month"
                      no-header
                    />
                  </div>
                </div>
                <div class="d-flex mr-3" />
              </div>
              <b-table
                :fields="modal"
                :items="skill_members"
                class="modal-table approvel-table"
              >
                <!-- v-else -->
                <!-- A custom formatted data column cell -->
                <template #cell(name)="data">

                  <div class="resource-user-data">
                    <div class="available availibility" />
                    <div class="resource-user user-name">
                      <b-avatar
                        size="md"
                        :src="data.item.picture"
                        :text="data.item.full_name | avatarText"
                        :variant="randomVariant(1, items.length)"
                      />
                      <!-- {{ data.item.project.name }}
                        <span>({{ data.item.project.code }})</span> -->
                      {{ data.item.full_name }}
                    </div>
                  </div>
                </template>

                <template #cell(email)="data">
                  <div class="resource-user-total quarter-hour available">
                    <span>{{ data.item.email }}</span>
                  </div>
                </template>
                <template #cell(skill_time_type)="data">
                  <div class="resource-user-total quarter-hour available">
                    <span>{{ data.item.type }}</span>
                  </div>
                </template>
              </b-table>

              <p v-if="skill_members && !skill_members.length">
                No data found
              </p>
            </div>
          </b-modal>
        </div>
        <div
          v-else
          class="w-100"
        >
          <img
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VBToggle, BTable,
} from 'bootstrap-vue'
import * as moment from 'moment/moment'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { VueDatePicker } from '@mathieustan/vue-datepicker'

export default {
  name: 'HeaderBlock',
  components: {
    customDropdown,
    VueDatePicker,
    BTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },

  filters: {
    DateDDMMYYYYFormat(value) {
      return moment(value).format('DD-MM-YYYY')
    },

  },
  data() {
    return {
      manage_by: null,
      primary_skill_id: null,
      skill_type: null,
      skill_name: null,
      is_model: false,
      reporting_to: null,
      reporting_to_users: [],
      manage_by_users: [],
      group_id: null,
      groups: [],
      loader: false,
      modalDate: null,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      available_total: 0,
      full_time_total: 0,
      part_time_total: 0,
      all_total: 0,
      month: moment(this.startDate).startOf('month').format('MM'),
      year: moment(this.startDate).startOf('month').format('YYYY'),
      fields: [
        {
          key: 'name',
          label: 'Skill',
        },
        {
          key: 'full_time',
          label: 'Full Time',
        },
        {
          key: 'part_time',
          label: 'Part Time',
        },
        {
          key: 'available',
          label: 'Available',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'total',
          label: 'Total',
        },

      ],
      items: [],
      modal: [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'skill_time_type', label: 'Allocation' },
      ],
      modalItems: [],
      allocations: [
        {
          value: 'available',
          label: 'Available',
        },
        {
          value: 'parttime',
          label: 'Part Time',
        },
        {
          value: 'fulltime',
          label: 'Full Time',
        },
      ],
      skill_members: [],
    }
  },
  watch: {
    startDate(nv) {
      if (nv) {
        setTimeout(() => {
          this.skillReport()
        }, 200)
      }
    },
    modalDate() {
      this.skillMembersList()
    },
  },
  mounted() {
    if (this.userInfo.role != 'SA') {
      this.manage_by = this.userInfo.id
    }
    this.filterFunctionCallFromMixin()
    this.skillReport()
  },
  methods: {
    async filterFunctionCallFromMixin() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        this.groups = await this.getGroupData()
      }
      this.manage_by_users = await this.manageByUserList()
      this.reporting_to_users = await this.reportingtoUserList()
    },

    async skillReport() {
      this.loader = true
      const input = {
        month: moment(this.startDate).startOf('month').format('MM'),
        year: moment(this.startDate).startOf('month').format('YYYY'),
        manage_by: this.manage_by ? this.manage_by : null,
        reporting_to: this.reporting_to ? this.reporting_to : null,
        group_id: this.group_id ? this.group_id : null,
      }
      const response = await this.getHTTPPostResponse('admin/master/reports/skill-report', input, false)
      if (response && response.data) {
        const { data } = response
        this.items = data.skill_report

        this.allTotal(this.items)
      }
      this.loader = false
    },
    async skillMembersList() {
      const input = {
        manage_by: this.manage_by ? this.manage_by : null,
        reporting_to: this.reporting_to ? this.reporting_to : null,
        group_id: this.group_id ? this.group_id : null,
        month: moment(this.modalDate, 'YYYY-MM').startOf('month').format('MM'),
        year: moment(this.modalDate, 'YYYY-MM').startOf('month').format('YYYY'),
        primary_skill_id: this.primary_skill_id,
        skill_type: this.skill_type,
      }

      if (this.modalDate) {
        const response = await this.getHTTPPostResponse(
          'admin/master/reports/skill-members',
          input,
          false,
        )
        if (response && response.data) {
          const { data } = response
          this.skill_members = data.skill_members
        }
      }
    },
    allTotal(data) {
      this.available_total = 0
      this.full_time_total = 0
      this.part_time_total = 0
      this.all_total = 0
      data.forEach(element => {
        this.available_total += element.skills.available ? element.skills.available : 0
        this.part_time_total += element.skills.part_time ? element.skills.part_time : 0
        this.full_time_total += element.skills.fullTime ? element.skills.fullTime : 0
        this.all_total += element.skills.total ? element.skills.total : 0
      })
    },
    ApplyFilter() {
      this.manage_by = this.manage_by ? this.manage_by : null
      this.reporting_to = this.reporting_to ? this.reporting_to : null
      this.skillReport()
    },
    clearFilter() {
      if (this.userInfo.role != 'SA') {
        this.manage_by = this.userInfo.id
      } else {
        this.manage_by = null
      }
      this.available_total = 0
      this.full_time_total = 0
      this.part_time_total = 0
      this.all_total = 0
      this.reporting_to = null
      this.group_id = null
      this.startDate = moment().startOf('month').format('YYYY-MM-DD')
      this.skillReport()
    },

    showSkillMemberModel(data, type = null) {
      const skillType = type === 'full_time' ? 'fullTime' : type === 'part_time' ? 'partTime' : type
      const typeValue = data.skills[skillType]
      if (data && typeValue > 0) {
        this.is_model = true
        this.skill_type = type
        this.primary_skill_id = data.id
        this.skill_name = data.name
        this.modalDate = moment(this.startDate, 'YYYY-MM').format('YYYY-MM')
        this.skillMembersList()
      }
    },

    closeModal() {
      this.skill_type = null
      this.primary_skill_id = null
      this.skill_name = null
    },

    getTooltipText(value) {
      return value > 1 ? `${value} Members` : `${value} Member`
    },
  },
}
</script>

<style lang="scss" >
@import "@/assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/report.css";

.label-sweet {
  color: #5e5873 !important;
  font-size: 14px;
  margin-left: 10px;
}

.input-aleart {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  accent-color: #fb5362;
  overflow-y: hidden;
}

.report-loader-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1080;

  img {
    width: 100px;
  }

  .overdlow-sheet {
    overflow-y: hidden;
  }
}

.task-timesheet {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

}

.custom-drop-report {
  &.side-bar-clear {
    padding: 10px 20px;
  }

  .dropdown-toggle {
    color: #f97753 !important;
  }

  .dropdown-item:hover,
  [dir] .dropdown-item:focus {
    background-color: rgba(249, 119, 83, 0.12) !important;
    color: #f97753;
  }

  .dropdown-menu {
    border-radius: 0.358rem;
    transform: scale(1, 0);
    box-shadow: 0 5px 25px rgb(34 41 47 / 10%) !important;
    background-color: #fff !important;
    left: 0px !important;
  }
}

.b-table-bottom-row {
  position: sticky;
  bottom: -1px;
}

.b-table-sticky-header {
  overflow-y: auto;

  max-height: calc(100vh - 190px);
}
.report-view.skill-report table.table tr th {
  padding: 16px;
  background: #f3f2f7;
  border-bottom: 0 !important;
  top: -1px;
}
</style>
